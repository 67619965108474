/* Testimonials.module.css */
.testimonials {
  text-align: center;
  padding: 40px 20px;
}

.h2Font {
  font-size: 2rem; /* Adjust as needed */
  margin-bottom: 10px;
}

.pFont {
  font-size: 1rem; /* Adjust as needed */
  margin-bottom: 20px;
}

.testimonialCards {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between cards */
  flex-wrap: wrap; /* Wrap to new line if necessary */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px; /* Fixed width for each card */
  max-width: 90%; /* Responsive max width */
}

.footer {
  align-items: flex-end;
  margin-top: 20px;
  text-align: center;
}

.avatar {
  width: 50px; /* Avatar size */
  height: 50px; /* Avatar size */
  border-radius: 50%;
  margin-right: 10px; /* Space between image and text */
}

.indicator {
  /* Styles for indicator dots */
}