/* FloatingButton.module.css */
.floatingButton {
  position: fixed;
  bottom: 20px; /* Space from the bottom */
  right: 20px;  /* Space from the right */
  background-color: #fff; /* Button color */
  color: #B20404; /* Text color */
  border: none; /* No border */
  border-radius: 20%; /* Round shape */
   /* Width of the button */
  height: 60px; /* Height of the button */
  font-size: 14px; /* Font size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  cursor: pointer; /* Cursor on hover */
  transition: background-color 0.3s; /* Transition for hover effect */
  z-index: 1000;   
}

.floatingButton:hover {
  background-color: #fff; /* Darker shade on hover */
  color: rgb(178 4 3);;
}